.App {
	background-color: #333;
}

.App-logo {
	position: absolute;
	top: 4vh;
	left: 4vh;
	height: 50px;
	pointer-events: none;
}

.Report-screenshot {
	height: 70vh;
	/* float: left; */
	pointer-events: none;
	margin-top:5vh;
	margin-left: 10vh;
}

.Welcome-text {	
	position: absolute;
	bottom: 2vh;
	right: 4vw;
	text-align: left;
	margin: 4vh;
	max-width: 50vw;
}

@media (max-width: 1350px) {
	.Welcome-text {
		position: inherit;
		max-width: 80vw;
	}
}

.Welcome-text h3 {
	color: #C6E1E6 !important;
}

.Welcome-text p {
	color: white;
}

.Welcome-text p {
	color: white;
}

.Welcome-note {
	font-size: 0.6em;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #22BCAA;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #fff;
  background-color: #771A6B;
  border-radius: 12px;
  text-decoration: none;
  padding: 16px;
  text-align: center;
  width: 150px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
